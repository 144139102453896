import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {

    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">沈伯丞 x 何孟娟
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">《魏絲貝斯計畫》</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_gift_a">
            <div className="column_3">
              <div className="context">
                <p>望向窗外，我看見了光和天際線，還有那些追求著成就與愛以及全世界最大的巧克力棒點心，而我的心油然隨之翩然起舞＿《胃灼熱》. 諾拉・艾芙隆</p>
              </div>
            </div>
            <div className="column_3">
              <div className="context">
                <p>“I look out the window and I see the lights and the skyline and the people on the street rushing around looking for action, love, and the world’s greatest chocolate chip cookie, and my heart does a little dance.”＿“Heartburn”, by Nora Ephron</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_b row_gallery">
            <div className="column_6">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/20am3T7uTwQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">Hugh Seidman</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_c">
            <div className="column_6 onset_left_1">
              <div className="context">
                <p><b>「人生很短，一轉身就是一輩子。」</b>張愛玲的文字，總是在寥寥數語間，給了讀者一種痛快又殘酷的通透。「零落愁寂寡歡趣。」這樣的生命情境，是城市裡熙來攘往的風華年少，很難經歷、想像與感受到的生命情境，然而那卻是所有芳華轉身間的必然，「老」一如王國維的詞句：「朱顏辭鏡花辭樹」；或許正是這「最是人間留不住」的惆悵與必然，讓世世代代的騷人墨客，總是情不自禁地將眼光與情感投注於，生命最為精淬與感傷的終章。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_d row_gallery">
            <div className="column_6 onset_left_2 gallery_wrap">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yWsg3tG_ZvE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">Madeleine Yayodele Nelson</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_e">
            <div className="column_7 onset_left_1">
              <div className="context">
                <p>鏡頭後的何孟娟，或許也和那些吟詠著生命的前輩般，凝視著那轉身後的人生，以一種女性獨有的細膩感傷視角，深深地將這些轉身後的形影、音聲，細細琢磨、刻畫在影格和相紙裡。何孟娟的「魏斯貝斯」是屬於大都會的人生故事，那是費茲傑羅筆下：「我們便這樣揚著船帆迂迴前進，逆水行舟，而浪潮奔流不歇，又不停地將我們推向過去。」的人生，是百老匯歌舞劇〈亞力山大・漢彌頓〉（Alexander Hamilton）歌詞中：「在紐約，你能成為一個全新的人。」（In New York you can be a new man.）的人生，那是交雜著夢想、挫折、激情與現實，愛與殘酷的人生，是即使遍體鱗傷，卻充盈著感動和滿足，因為你知道自己真正活過。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_f row_gallery">
            <div className="column_6 gallery_wrap">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/GP2UNlO1cxU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">Pat Lash</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_g">
            <div className="column_7">
              <div className="context">
                <p>影像中，曾經這公寓裡的長者們和鏡頭後的藝術家一樣，以激情和愛毫不遲疑地投入那稱為藝術家的人生，在大蘋果裡橫衝直撞地，嘗試踏上那舞台燈光聚焦的位置，高聲地唱出自己的人生與夢…曾經，他們的畫筆、鏡頭、相機、打字機、雕刀…都猶如，搖滾樂手的演奏般，激昂飛舞著自己的人生夢想，直到轉身前…。甚至，至今這些銀霜紛飛的長者們，依舊保持著、綻放著在現實生活的鉗槌焠鍊後，生命與夢想依然純淨的身姿。在「魏絲貝斯」這老公寓裡，每個小單位都曾經是一個風華少年向世界出發的起點，如今這個小角落成為了轉身後的整個世界…。</p>
              </div>
            </div>
          </section>
          
          <section className="row_area_s project_a_gift_h">
            <div className="column_5">
              <div className="context">
                <p>錯落在宛如棋盤分布的紐約一隅，魏斯貝斯一如其他城市裡的公寓般，蘊藏著人的故事，那是間雜著當建築陰影壓得心情低迷時，落日就在街道的盡頭上方，召喚著希望與夢想的生活故事，而何孟娟的鏡頭讓我們看見了這些轉身後依舊美麗、純淨的生命姿態。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_h row_gallery">
            <div className="column_6 onset_left_1 gallery_wrap">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/2bHxmI3ORpo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">Peter Kopf</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_f">
            <div className="column_6 onset_left_2">
              <div className="context">
                <p>展覽企劃<i>「獻給他的禮物，成為我的謬思」</i>嘗試透過何孟娟的鏡頭，去勾勒城市的另一個常見卻又陌生的存在，那曾經努力過、激情過、夢想過後的「銀霜年歲」人生。「人生」未必總是風華美好、光彩奪目，何孟娟的影像提醒著我們「每當你想批評人的時候，要記得，世上不是所有人都像你一樣擁有許多優勢。…」（Whenever you feel like criticizing any one," he told me, "just remember that all the people in this world haven't had the advantages that you've had."）這句《大亨小傳》深刻又沈重的控訴。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_f">
            <div className="column_5 onset_left_3">
              <div className="context">
                <p>凝視著何孟娟鏡頭前的身影，<i>「藝術是一種存活的方式」（Art is a way for survival）</i>從而有了骨肉血脈。「公眾」是由每一個人所組成的有機體，而其靈魂則是集體記憶和情感交織出的情景。在紐約的老公寓裡，何孟娟無意間揭露了「公共」與「藝術」之間那最為深情的連結：『沒有人應該被遺忘，「城市」的故事就是『人』的故事，而藝術揭露了靈魂最深處的面貌』。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_h row_gallery">
            <div className="column_6 gallery_wrap">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/EG7coBnjbpM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">2013 Harold Miller’s Party</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_gift_f">
            <div className="column_4">
              <div className="context">
                <p>鏡頭前的身影和公寓，身處「紐約」抑或「台北」並不重要，真正重要的是那轉身前、後「人生」的遞嬗，而城市的記憶就這麼在身影遞嬗間，逐漸拓展、蔓延，「閱盡天涯離別苦，不道歸來，零落花如許。」，在城市的靈魂裡那些屬於滄桑、洞察與練達的智慧，全因何孟娟鏡頭前的那些身影，細細回憶、吟唱…。從轉身後的觀景窗望出去，城市的光和天際線因人的身影而豐富動容了起來，只因那蘊藏著許許多人的故事，在何孟娟的影像裡。</p>
              </div>
            </div>
          </section>

        </div>

        <div className="head_intro_content headroom">
          <h5 className="name">何孟娟</h5>
          <p className="bio">台北藝術大學美術創作碩士，現從事當代攝影。2018拉古納藝術大獎特別獎、2013與2015 紐約ISCP5駐村、2014年慕尼黑以及吉隆坡駐村、2010年巴黎西帖藝術村駐村、2013伊勢基金會獎、2016、2012年亞洲文化協會受獎人、2011年高雄獎首獎。作品典藏於國立台灣美術館、高雄市立美術館、關渡美術館、鳳甲美術館、澳洲白兔美術館、韓國光州美術館。曾受邀於以色列、法國、德國、義大利、西班牙、捷克、印度、韓國、中國、美國等地展出。</p>
        </div>

        <div className="page_footer">
          <Link to="/project_a_muse" className="next_page">
            <div className="next_title">台北萬華 最美的是人</div>
            <div className="next_owner">林立青</div>
          </Link>
        </div>

      </div>

    )

  }

}


export default Page

